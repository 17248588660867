// Step 1: Import your component
import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Container from "../components/Container"


const pageStyles = {
  color: "#232129",
  fontFamily: "Open Sans Condensed",
};

const Datenschutz = () => {
  return (
    <main style={pageStyles}>
      <title>Impressum</title>
      <Header />
      <Container>
      <h1 class="adsimple-111901767 fusion-responsive-typography-calculated" data-fontsize="32" data-lineheight="44.79999923706055px">Datenschutzerkl&auml;rung</h1>
<h2 id="einleitung-ueberblick" class="adsimple-111901767 fusion-responsive-typography-calculated" data-fontsize="24" data-lineheight="36px">Einleitung und &Uuml;berblick</h2>
<p>Wir haben diese Datenschutzerkl&auml;rung (Fassung 18.12.2021-111901767) verfasst, um Ihnen gem&auml;&szlig; der Vorgaben der <a class="adsimple-111901767" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111901767"  rel="noreferrer" target="_blank" rel="noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erkl&auml;ren, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &ndash; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &ndash; verarbeiten, zuk&uuml;nftig verarbeiten werden und welche rechtm&auml;&szlig;igen M&ouml;glichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br /><strong class="adsimple-111901767">Kurz gesagt:</strong> Wir informieren Sie umfassend &uuml;ber Daten, die wir &uuml;ber Sie verarbeiten.</p>
<p>Datenschutzerkl&auml;rungen klingen f&uuml;r gew&ouml;hnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerkl&auml;rung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie m&ouml;glich beschreiben. Soweit es der Transparenz f&ouml;rderlich ist, werden technische <strong class="adsimple-111901767">Begriffe leserfreundlich erkl&auml;rt</strong>, <strong class="adsimple-111901767">Links</strong> zu weiterf&uuml;hrenden Informationen geboten und <strong class="adsimple-111901767">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Gesch&auml;ftst&auml;tigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht m&ouml;glich, wenn man m&ouml;glichst knappe, unklare und juristisch-technische Erkl&auml;rungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erl&auml;uterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />Wenn trotzdem Fragen bleiben, m&ouml;chten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverst&auml;ndlich auch im Impressum.</p>
<h2 id="anwendungsbereich" class="adsimple-111901767 fusion-responsive-typography-calculated" data-fontsize="24" data-lineheight="36px">Anwendungsbereich</h2>
<p>Diese Datenschutzerkl&auml;rung gilt f&uuml;r alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und f&uuml;r alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt daf&uuml;r, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen k&ouml;nnen, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerkl&auml;rung umfasst:</p>
<ul class="adsimple-111901767">
<li class="adsimple-111901767">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
<li class="adsimple-111901767">Social Media Auftritte und E-Mail-Kommunikation</li>
<li class="adsimple-111901767">mobile Apps f&uuml;r Smartphones und andere Ger&auml;te</li>
</ul>
<p><strong class="adsimple-111901767">Kurz gesagt:</strong> Die Datenschutzerkl&auml;rung gilt f&uuml;r alle Bereiche, in denen personenbezogene Daten im Unternehmen &uuml;ber die genannten Kan&auml;le strukturiert verarbeitet werden. Sollten wir au&szlig;erhalb dieser Kan&auml;le mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
<h2 id="rechtsgrundlagen" class="adsimple-111901767 fusion-responsive-typography-calculated" data-fontsize="24" data-lineheight="36px">Rechtsgrundlagen</h2>
<p>In der folgenden Datenschutzerkl&auml;rung geben wir Ihnen transparente Informationen zu den rechtlichen Grunds&auml;tzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns erm&ouml;glichen, personenbezogene Daten zu verarbeiten.<br />Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROP&Auml;ISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU k&ouml;nnen Sie selbstverst&auml;ndlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-111901767" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111901767" rel="noreferrer" target="_blank" rel="noopener">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
<li class="adsimple-111901767"><strong class="adsimple-111901767">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel w&auml;re die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
<li class="adsimple-111901767"><strong class="adsimple-111901767">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erf&uuml;llen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschlie&szlig;en, ben&ouml;tigen wir vorab personenbezogene Informationen.</li>
<li class="adsimple-111901767"><strong class="adsimple-111901767">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen f&uuml;r die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
<li class="adsimple-111901767"><strong class="adsimple-111901767">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschr&auml;nken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir m&uuml;ssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu k&ouml;nnen. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im &ouml;ffentlichen Interesse und Aus&uuml;bung &ouml;ffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschl&auml;gig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p>Zus&auml;tzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul class="adsimple-111901767">
<li class="adsimple-111901767">In <strong class="adsimple-111901767">&Ouml;sterreich</strong> ist dies das Bundesgesetz zum Schutz nat&uuml;rlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-111901767">Datenschutzgesetz</strong>), kurz <strong class="adsimple-111901767">DSG</strong>.</li>
<li class="adsimple-111901767">In <strong class="adsimple-111901767">Deutschland</strong> gilt das <strong class="adsimple-111901767">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-111901767"> BDSG</strong>.</li>
</ul>
<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten dar&uuml;ber.</p>
<h2 id="kontaktdaten-verantwortliche" class="adsimple-111901767 fusion-responsive-typography-calculated" data-fontsize="24" data-lineheight="36px">Kontaktdaten des Verantwortlichen</h2>
<p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br /><span class="adsimple-111901767">Silvija Podgorac<br />Praxisadresse: Hollgasse 6/3/R01, 1050 Wien, &Ouml;sterreich</span></p>
<p>E-Mail: <a>s.podgorac@me.com</a><br />Telefon: <a>+43 699/11827092</a><br />Impressum: <a href="https://lebensfluss.art/impressum">https://lebensfluss.art/impressum</a></p>
<h2 id="speicherdauer" class="adsimple-111901767 fusion-responsive-typography-calculated" data-fontsize="24" data-lineheight="36px">Speicherdauer</h2>
<p>Dass wir personenbezogene Daten nur so lange speichern, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten l&ouml;schen, sobald der Grund f&uuml;r die Datenverarbeitung nicht mehr vorhanden ist. In einigen F&auml;llen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des urspr&uuml;ngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchf&uuml;hrung.</p>
<p>Sollten Sie die L&ouml;schung Ihrer Daten w&uuml;nschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie m&ouml;glich und soweit keine Pflicht zur Speicherung besteht, gel&ouml;scht.</p>
<p>&Uuml;ber die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
<h2 id="rechte-dsgvo" class="adsimple-111901767 fusion-responsive-typography-calculated" data-fontsize="24" data-lineheight="36px">Rechte laut Datenschutz-Grundverordnung</h2>
<p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul class="adsimple-111901767">
<li class="adsimple-111901767">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht dar&uuml;ber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
<ul class="adsimple-111901767">
<li class="adsimple-111901767">zu welchem Zweck wir die Verarbeitung durchf&uuml;hren;</li>
<li class="adsimple-111901767">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
<li class="adsimple-111901767">wer diese Daten erh&auml;lt und wenn die Daten an Drittl&auml;nder &uuml;bermittelt werden, wie die Sicherheit garantiert werden kann;</li>
<li class="adsimple-111901767">wie lange die Daten gespeichert werden;</li>
<li class="adsimple-111901767">das Bestehen des Rechts auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
<li class="adsimple-111901767">dass Sie sich bei einer Aufsichtsbeh&ouml;rde beschweren k&ouml;nnen (Links zu diesen Beh&ouml;rden finden Sie weiter unten);</li>
<li class="adsimple-111901767">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
<li class="adsimple-111901767">ob Profiling durchgef&uuml;hrt wird, ob also Daten automatisch ausgewertet werden, um zu einem pers&ouml;nlichen Profil von Ihnen zu gelangen.</li>
</ul>
</li>
<li class="adsimple-111901767">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen m&uuml;ssen, falls Sie Fehler finden.</li>
<li class="adsimple-111901767">Sie haben laut Artikel 17 DSGVO das Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;), was konkret bedeutet, dass Sie die L&ouml;schung Ihrer Daten verlangen d&uuml;rfen.</li>
<li class="adsimple-111901767">Sie haben laut Artikel 18 DSGVO das Recht auf Einschr&auml;nkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern d&uuml;rfen aber nicht weiter verwenden.</li>
<li class="adsimple-111901767">Sie haben laut Artikel 19 DSGVO das Recht auf Daten&uuml;bertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem g&auml;ngigen Format zur Verf&uuml;gung stellen.</li>
<li class="adsimple-111901767">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine &Auml;nderung der Verarbeitung mit sich bringt.
<ul class="adsimple-111901767">
<li class="adsimple-111901767">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (&ouml;ffentliches Interesse, Aus&uuml;bung &ouml;ffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, k&ouml;nnen Sie gegen die Verarbeitung Widerspruch einlegen. Wir pr&uuml;fen danach so rasch wie m&ouml;glich, ob wir diesem Widerspruch rechtlich nachkommen k&ouml;nnen.</li>
<li class="adsimple-111901767">Werden Daten verwendet, um Direktwerbung zu betreiben, k&ouml;nnen Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen Ihre Daten danach nicht mehr f&uuml;r Direktmarketing verwenden.</li>
<li class="adsimple-111901767">Werden Daten verwendet, um Profiling zu betreiben, k&ouml;nnen Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen Ihre Daten danach nicht mehr f&uuml;r Profiling verwenden.</li>
</ul>
</li>
<li class="adsimple-111901767">Sie haben laut Artikel 22 DSGVO unter Umst&auml;nden das Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
</ul>
<p><strong class="adsimple-111901767">Kurz gesagt:</strong> Sie haben Rechte &ndash; z&ouml;gern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen Anspr&uuml;che in sonst einer Weise verletzt worden sind, k&ouml;nnen Sie sich bei der Aufsichtsbeh&ouml;rde beschweren. Diese ist f&uuml;r &Ouml;sterreich die Datenschutzbeh&ouml;rde, deren Website Sie unter <a class="adsimple-111901767" href="https://www.dsb.gv.at/?tid=111901767"  rel="noreferrer" target="_blank" >https://www.dsb.gv.at/</a> finden. In Deutschland gibt es f&uuml;r jedes Bundesland einen Datenschutzbeauftragten. F&uuml;r n&auml;here Informationen k&ouml;nnen Sie sich an die <a class="adsimple-111901767" href="https://www.bfdi.bund.de/" target="_blank" rel="noreferrer">Bundesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. F&uuml;r unser Unternehmen ist die folgende lokale Datenschutzbeh&ouml;rde zust&auml;ndig:</p>
<p>Alle Texte sind urheberrechtlich gesch&uuml;tzt.</p>
<p>Quelle: Erstellt mit dem <a title="Datenschutz Generator von AdSimple f&uuml;r &Ouml;sterreich" href="https://www.adsimple.at/datenschutz-generator/">Datenschutz Generator</a> von AdSimple</p>
        
      </Container>
      <Footer />
    </main>
  );
};

export default Datenschutz;
